@import "@design";

.foreman-cell {
  display: flex;
  min-width: 80px;
  min-height: 20px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 500;
  background: lighten(black, 85%);
  border-color: darken(#d2d2d2, 6);
  border-bottom-width: 0;

  @media only screen and (max-width: 992px) {
    font-size: 0.8rem;
  }

  @media print {
    font-size: 0.7rem;
    font-weight: bold;
    min-width: 80px;
    padding: 0 !important;
  }

  &.inactive-foreman {
    color: #4a4a4a;
    background: lighten(black, 75%);
  }

  .cal-row.last & {
    border-bottom-width: 1px;
  }
}
